#loadingCircle {
    animation-name: ckw;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    display: inline-block;
}
@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
